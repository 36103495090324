import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import Image from "react-bootstrap/Image";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { EMAIL_REGEX, PHONE_REGEX_WITHOUT_SPACES } from "../../utils/constants";
import { selectLangState } from "../../redux/configurationSlice";
import { AuthAPI } from "../../apis/authApi";
import InputLabel from "../Form/InputLabel";
import InputField from "../Form/InputField";
import FieldValidationText from "../Global/fieldValidationText";
import useForm from "../../hooks/useForm";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import ButtonWithLoading from "../buttons/ButtonWithLoading";

const ForgotPassword = ({ setCurrent, setState, state }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { lang } = useSelector(selectLangState);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [token, setToken] = useState("");
  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors, isDirty, isValid, isSubmitting },
    handleChange,
  } = useForm({ mode: "all" });
  const onSuccess = (response) => {
    toast.success(t("otp send"));
  };

  const {
    mutateAsync: sendOtp,
    status,
    error,
  } = useMutation({
    mutationFn: (data) => AuthAPI.sendOtp(data),
    onSuccess,
  });

  const onSubmit = useCallback(
    async (values) => {
      try {
        const data = {
          otp_type: "FORGET_PASSWORD",
          company: "ESD",
          platform: "website",
          language: lang?.startsWith("ar") ? "ar" : "en",
        };
        if (!executeRecaptcha) {
          toast.error(t("Captcha missing"));
          return;
        }
        const result = await executeRecaptcha("password");

        if (result) {
          data.captcha_token = result;
        }
        if (EMAIL_REGEX.test(values?.email)) {
          data.email = values?.email;
          const response = await AuthAPI.sendForgotEmail(data);
          toast.success(response?.message);
          navigate("/login");
          setCurrent(0);
          setState((prev) => ({
            ...prev,
            email: null,
            mobile_no: null,
          }));
        } else {
          data.mobile_no = values?.email;
          setState((prev) => ({
            ...prev,
            email: null,
            mobile_no: values?.email,
          }));
          await sendOtp(data);
          setCurrent(1);
        }
      } catch (error) {
        toast.error(error?.message);
      }
    },
    [executeRecaptcha]
  );

  const captchaLoad = async () => {
    try {
      const token = await executeRecaptcha("password");
      setToken(token);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    executeRecaptcha && captchaLoad();
  }, [executeRecaptcha]);

  return (
    <>
      <div className="bg-auth">
        <Image
          src="/images/bg-auth.png"
          alt="icon-uae-pass"
          className="d-inline-block align-middle me-2"
        />
      </div>
      <div className="auth d-flex align-items-center justify-content-center">
        <div className="center-content w-100">
          <h1 className="fw-700 text-white font-bukra">
            {t("Forgot password?")}
            <span className="d-block fw-400 subTitle font-dubai text-grey-400">
              {t("emailPhoneVerifyText")}.
            </span>
          </h1>
          <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Form.Group className="mb-3">
              <InputLabel
                className="text-white"
                text={`${t("Email address / Mobile no")}.`}
                htmlFor="emailField"
              />
              <InputField
                className="text-white"
                {...register("email", {
                  required: t("Email is required"),
                  validate: (value) => {
                    if (
                      EMAIL_REGEX.test(value) ||
                      PHONE_REGEX_WITHOUT_SPACES.test(value)
                    ) {
                      return true;
                    }
                    return t("Enter valid email or phone number");
                  },
                })}
                placeholder="e.g, adam@abc.com /+971 XX XXX XXXX"
                id="emailField"
                onChange={(e) => handleChange(e)}
              />
              {errors.email && (
                <FieldValidationText errorMessage={errors.email?.message} />
              )}
            </Form.Group>
            <ButtonWithLoading
              text={t("Next")}
              className="btn btn-primary fw-500 w-100" //to disable add class "disabled"
              type="submit"
              isLoading={isSubmitting}
              disabled={!isValid || !isDirty || isSubmitting}
            />
          </Form>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
