import { Col, Container, Row, Tabs } from "react-bootstrap";
import {
  AdvancedMarker,
  InfoWindow,
  Map,
  Marker,
  Pin,
  useAdvancedMarkerRef,
} from "@vis.gl/react-google-maps";
import Image from "react-bootstrap/Image";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Dropdown from "react-bootstrap/Dropdown";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import OfferCard from "../Components/Global/OfferCard";
import CategoryFilter from "../Components/Category/CategoryFilters";
import Button from "react-bootstrap/Button";
import CardTile from "../Components/Global/CardTile";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { HomeAPI } from "../apis/homeApi";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectLangState } from "../redux/configurationSlice";
import { memo, useEffect, useState } from "react";
import { CategoryAPI } from "../apis/categoryApi";
import { capitalizeAndSpace } from "../utils/utilityFunctions";
import Pagination from "../Components/Global/Pagination";
import PageLoader from "../Components/loaders/pageLoader";
import NoDataComponent from "../Components/Global/NoDataContent";
import { SearchAPI } from "../apis/searchApi";

const SearchListing = () => {
  const [activeTab, setActiveTab] = useState("gridView");
  const { text } = useParams();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const subCategories = searchParams.get("sub")
    ? searchParams.get("sub")?.split(",")
    : [];
  const offerTypes = searchParams.get("type");
  const sort = searchParams.get("sort");
  const min = searchParams.get("min");
  const max = searchParams.get("max");
  const ratingmin = searchParams.get("ratingmin");
  const ratingmax = searchParams.get("ratingmax");

  const [isZoomEnabled, setIsZoomEnabled] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [mapProps, setMapProps] = useState({
    center: [25.205200541193324, 55.27186452531266],
    zoom: 10,
  });
  const { t } = useTranslation();
  const { lang } = useSelector(selectLangState);
  const { data, refetch, isLoading, status } = useQuery({
    queryKey: [
      "search-oultets",
      {
        company: "ESD",
        location_id: 0,
        language: lang?.startsWith("ar") ? "ar" : "en",
        platform: "WEB",
        lat: "25.205200541193324",
        lng: "55.27186452531266",
        limit: pageSize,
        offset: (currentPage - 1) * pageSize,
        ...(text ? { query_str: text } : {}),
        ...(text ? { fuzzy: true } : {}),
        ...(subCategories && subCategories?.length > 0
          ? { sub_category: subCategories }
          : {}),
        ...(offerTypes ? { offer_type: offerTypes } : {}),
        ...(sort ? { [sort]: true } : {}),
        ...(min && max ? { discount_range: [min, max] } : {}),
      },
    ],
    queryFn: ({ queryKey }) => SearchAPI.search({ ...queryKey[1] }),
    enabled: !!text,
  });

  const handleMapClick = () => {
    setIsZoomEnabled((prev) => !prev);
    setSelectedMarker(null);
  };

  const handleMouseLeave = () => {
    setIsZoomEnabled(false);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemPerPageChange = (itemPerPage) => {
    setPageSize(itemPerPage);
  };

  const getPageCount = () => {
    return Math.ceil((data?.pagination?.total_records ?? 0) / pageSize);
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setMapProps((prev) => ({
            ...prev,
            zoom: 12,
            center: [latitude, longitude],
          }));
        },
        (error) => {
          console.error("Error getting current position:", error);
        }
      );
    }
  }, []);

  useEffect(() => {
    if (selectedMarker && data) {
      const selectedMarkerData = data?.outlets?.find(
        (outlet) => outlet.id === selectedMarker.id
      );
      if (selectedMarkerData) {
        setSelectedMarker((prev) => ({
          ...prev,
          is_favourite: selectedMarkerData?.is_favourite,
        }));
      }
    }
  }, [data]);

  const removeSubCategory = (subCategoryToRemove) => {
    const updatedSubCategories = subCategories.filter(
      (val) => val !== subCategoryToRemove
    );

    const newSearchParams = new URLSearchParams(searchParams);
    if (updatedSubCategories.length > 0) {
      newSearchParams.set("sub", updatedSubCategories.join(","));
    } else {
      newSearchParams.delete("sub");
    }

    setSearchParams(newSearchParams);
  };

  const removeOfferType = (offerTypeToRemove) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete("type");
    setSearchParams(newSearchParams);
  };

  const removeDiscountFilter = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete("min");
    newSearchParams.delete("max");
    setSearchParams(newSearchParams);
  };
  const clearRatingFilter = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete("ratingmin");
    newSearchParams.delete("ratingmax");
    setSearchParams(newSearchParams);
  };
  const clearSortFilter = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete("sort");
    setSearchParams(newSearchParams);
  };
  const removeAllFilters = () => {
    const newSearchParams = new URLSearchParams();

    newSearchParams.delete("sub");
    newSearchParams.delete("min");
    newSearchParams.delete("max");
    newSearchParams.delete("sort");
    newSearchParams.delete("type");

    setSearchParams(newSearchParams);
  };

  const getSearchParamsLength = () => {
    return Array.from(searchParams.entries()).length;
  };

  if (status === "pending") return <PageLoader />;

  return (
    <>
      <div className="contentHolder">
        <Container>
          <div className="title-lg page-head primary-color font-bukra">
            <span>{t("Search results for")}</span>{" "}
            <span className="fw-700">"{text}"</span>
          </div>
          <div className="bnImage d-none">
            <Image
              src="/images/categoryListing-banner.png"
              alt="Banner Image"
              className="d-block w-100"
            />
          </div>

          {getSearchParamsLength() > 0 && (
            <Row className="mb-4" style={{ paddingTop: "1px" }}>
              <Col xs={12} className="d-flex flex-wrap gap-2">
                {searchParams?.get("sub") &&
                  searchParams
                    ?.get("sub")
                    ?.split(",")
                    ?.map((subCategory, index) => (
                      <span
                        className="selectFilter-badge d-flex align-items-center"
                        key={index}
                      >
                        {subCategory}{" "}
                        <span
                          className="cross"
                          onClick={() => removeSubCategory(subCategory)}
                        >
                          x
                        </span>
                      </span>
                    ))}
                {searchParams?.get("type") &&
                  searchParams
                    ?.get("type")
                    ?.split(",")
                    ?.map((subCategory) => (
                      <span className="selectFilter-badge d-flex align-items-center">
                        {subCategory}{" "}
                        <span
                          className="cross"
                          onClick={() => removeOfferType(subCategory)}
                        >
                          x
                        </span>
                      </span>
                    ))}
                {searchParams?.get("min") && searchParams?.get("max") && (
                  <span className="selectFilter-badge d-flex align-items-center">
                    {searchParams?.get("min")}
                    {"-"}
                    {searchParams?.get("max")}
                    {"%"}
                    <span className="cross" onClick={removeDiscountFilter}>
                      x
                    </span>
                  </span>
                )}
                {searchParams?.get("ratingmin") &&
                  searchParams?.get("ratingmax") && (
                    <span className="selectFilter-badge d-flex align-items-center">
                      {searchParams?.get("ratingmin")}
                      {"-"}
                      {searchParams?.get("ratingmax")}
                      <span className="cross" onClick={clearRatingFilter}>
                        x
                      </span>
                    </span>
                  )}

                {searchParams?.get("sort") && (
                  <span className="selectFilter-badge d-flex align-items-center">
                    {capitalizeAndSpace(searchParams?.get("sort"))}{" "}
                    <span className="cross" onClick={clearSortFilter}>
                      x
                    </span>
                  </span>
                )}
                <Button
                  variant="primary"
                  className="clearFilter"
                  onClick={removeAllFilters}
                >
                  {t("Clear All")}
                </Button>
              </Col>
            </Row>
          )}
        </Container>

        <Container className="position-relative">
          <Row className="g-4">
            {data?.outlets?.length > 0 ? (
              data?.outlets?.map((offer, key) => (
                <Col xl={3} md={6} xs={12} key={key}>
                  <OfferCard
                    to={`/merchant-detail/${offer?.merchant_id}`}
                    discount={offer?.discount}
                    distance={offer?.distance}
                    rating={offer?.avg_rating}
                    tags={offer?.tags}
                    offer={{ ...offer, outlet_id: offer?.id }}
                    imageUrl={offer?.image_url}
                    offerTitle={offer?.merchant_name}
                    address={offer?.human_location}
                    category={offer?.category_name}
                    outlet={offer?.category_name}
                    offerType={offer?.type}
                    refetch={refetch}
                    isFavorite={offer?.is_favourite}
                    valid_till={offer?.valid_till_date}
                  />
                </Col>
              ))
            ) : (
              <NoDataComponent />
            )}
          </Row>
          {getPageCount() > 1 && (
            <Pagination
              handlePageChange={handlePageChange}
              currentPage={currentPage}
              getPageCount={getPageCount}
              handleItemPerPageChange={handleItemPerPageChange}
              pageSize={pageSize}
              totalRecords={data?.pagination?.total_records}
            />
          )}
        </Container>
      </div>
    </>
  );
};

const InfoComponent = memo(({ selectedMarker, setSelectedMarker, refetch }) => {
  return (
    <InfoWindow
      position={{
        lat: selectedMarker?.lat,
        lng: selectedMarker?.lng,
      }}
      onCloseClick={() => setSelectedMarker(null)}
    >
      <OfferCard
        to={`/merchant-detail/${selectedMarker?.merchant_id}`}
        imageUrl={selectedMarker?.image_url}
        offerTitle={selectedMarker?.merchant?.name}
        address={selectedMarker?.address}
        category={selectedMarker?.merchant_name}
        offerType={selectedMarker?.type}
        discount={selectedMarker?.discount}
        distance={selectedMarker?.distance}
        rating={selectedMarker?.avg_rating}
        outlet={selectedMarker?.merchant?.name_for_outlet}
        tags={selectedMarker?.tags}
        isFavorite={selectedMarker?.is_favourite}
        valid_till={selectedMarker?.valid_till_date}
        offer={{
          ...selectedMarker,
          outlet_id: selectedMarker?.id,
        }}
        refetch={refetch}
        showTravelIcons={true}
      />
    </InfoWindow>
  );
});

export default SearchListing;
