import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Form, ListGroup, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import InputField from "../Form/InputField";
import InputLabel from "../Form/InputLabel";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { AuthAPI } from "../../apis/authApi";
import {
  PASSWORD_DISALLOWED_REGEX,
  PASSWORD_REGEX,
} from "../../utils/constants";
import FieldValidationText from "../Global/fieldValidationText";
import { useSelector } from "react-redux";
import { selectLangState } from "../../redux/configurationSlice";
import { selectUserDetails } from "../../redux/authSlice";
import ButtonWithLoading from "../buttons/ButtonWithLoading";
import useForm from "../../hooks/useForm";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const ChangePassword = ({ refetch }) => {
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [token, setToken] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const user = useSelector(selectUserDetails);
  const { lang } = useSelector(selectLangState);
  const {
    handleSubmit,
    control,
    register,
    reset,
    watch,
    formState: { errors, isDirty, isSubmitting, isValid },
    handleChange,
  } = useForm({
    mode: "all",
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
    setIsActive(!isActive);
  };

  const onSuccess = (response) => {
    toast.success(response?.message);
    reset();
    if (user?.password_required) {
      refetch();
    }
  };
  const onError = (error) => {
    toast.error(error?.message);
  };
  const {
    mutateAsync: changePassword,
    status,
    error,
  } = useMutation({
    mutationFn: (data) =>
      !user?.password_required
        ? AuthAPI.changePassword(data)
        : AuthAPI.resetPassword(data),
    onSuccess,
    onError,
  });
  const onSubmit = useCallback(
    async (values) => {
      try {
        const data = {
          // current_password: values?.password,
          password: values?.newPassword,
          confirm_password: values?.confirmPassword,
        };
        if (!executeRecaptcha) {
          toast.error(t("Captcha missing"));
          return;
        }
        const result = await executeRecaptcha("password");

        if (result) {
          data.captcha_token = result;
        }

        if (!user?.password_required) {
          data.current_password = values?.password;
        }
        await changePassword({
          ...data,
          company: user?.company_code ?? "ESD",
          sub_company: user?.sub_company_code ?? null,
          location_id: 0,
          language: lang?.startsWith("ar") ? "ar" : "en",
          platform: "website",
        });
      } catch (error) {
        console.log(error);
      }
    },
    [executeRecaptcha]
  );

  const captchaLoad = async () => {
    try {
      const token = await executeRecaptcha("password");
      setToken(token);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    executeRecaptcha && captchaLoad();
  }, [executeRecaptcha]);

  return (
    <div className="profile-content">
      <div className="titleHead">
        <h2 className="font-bukra fw-700 m-0 text-black">
          {!user?.password_required ? t("Change Password") : t("Set Password")}
        </h2>
      </div>
      <div className="content-body">
        <div className="twoColumns">
          <Row>
            <Col md={6} className="colForm">
              <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                {!user?.password_required && (
                  <Form.Group className="mb-3" controlId="passwordField">
                    <InputLabel text={t("Current Password")} />
                    <div className="input-group position-relative mb-2">
                      <InputField
                        className=""
                        type={showPassword ? "text" : "password"}
                        placeholder={t("Enter current password")}
                        {...register("password", {
                          required: t("This field is required"),
                          pattern: {
                            value: PASSWORD_REGEX,
                            message: t("Please enter a valid password"),
                          },
                        })}
                        onChange={(e) =>
                          handleChange(e, PASSWORD_DISALLOWED_REGEX)
                        }
                      />
                      <span
                        className={`showPassword add position-absolute ${
                          isActive ? "active" : ""
                        }`}
                        onClick={togglePasswordVisibility}
                        style={{ cursor: "pointer" }}
                      >
                        <svg
                          width="18"
                          height="12"
                          viewBox="0 0 18 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9.0013 3.66669C7.7138 3.66669 6.7288 4.67752 6.7288 6.00002C6.7288 7.32252 7.7138 8.33335 9.0013 8.33335C10.2888 8.33335 11.2746 7.32252 11.2746 6.00002C11.2746 4.67752 10.2888 3.66669 9.0013 3.66669ZM9.0013 9.88919C6.88047 9.88919 5.2138 8.17835 5.2138 6.00002C5.2138 3.82252 6.88047 2.11085 9.0013 2.11085C11.1221 2.11085 12.7888 3.82252 12.7888 6.00002C12.7888 8.17835 11.1221 9.88919 9.0013 9.88919ZM9.0013 0.166687C5.2138 0.166687 1.95547 2.57752 0.667969 6.00002C1.95547 9.42252 5.2138 11.8334 9.0013 11.8334C12.7888 11.8334 16.0471 9.42252 17.3346 6.00002C16.0471 2.57752 12.7888 0.166687 9.0013 0.166687Z"
                            fill="#EEEEEE"
                          />
                        </svg>
                      </span>
                    </div>
                    {errors.password && (
                      <FieldValidationText
                        errorMessage={errors.password?.message}
                      />
                    )}
                  </Form.Group>
                )}
                <Form.Group className="mb-3" controlId="newPassword">
                  <InputLabel text={t("New Password")} />
                  <div className="input-group position-relative mb-2">
                    <InputField
                      className=""
                      type={showNewPassword ? "text" : "password"}
                      placeholder={t("Enter new password")}
                      {...register("newPassword", {
                        required: t("This field is required"),
                        pattern: {
                          value: PASSWORD_REGEX,
                          message: t("Please enter a valid password"),
                        },
                      })}
                      onChange={(e) =>
                        handleChange(e, PASSWORD_DISALLOWED_REGEX)
                      }
                    />
                    <span
                      className={`showPassword add position-absolute ${
                        showNewPassword ? "active" : ""
                      }`}
                      onClick={() => setShowNewPassword((prev) => !prev)}
                      style={{ cursor: "pointer" }}
                    >
                      <svg
                        width="18"
                        height="12"
                        viewBox="0 0 18 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9.0013 3.66669C7.7138 3.66669 6.7288 4.67752 6.7288 6.00002C6.7288 7.32252 7.7138 8.33335 9.0013 8.33335C10.2888 8.33335 11.2746 7.32252 11.2746 6.00002C11.2746 4.67752 10.2888 3.66669 9.0013 3.66669ZM9.0013 9.88919C6.88047 9.88919 5.2138 8.17835 5.2138 6.00002C5.2138 3.82252 6.88047 2.11085 9.0013 2.11085C11.1221 2.11085 12.7888 3.82252 12.7888 6.00002C12.7888 8.17835 11.1221 9.88919 9.0013 9.88919ZM9.0013 0.166687C5.2138 0.166687 1.95547 2.57752 0.667969 6.00002C1.95547 9.42252 5.2138 11.8334 9.0013 11.8334C12.7888 11.8334 16.0471 9.42252 17.3346 6.00002C16.0471 2.57752 12.7888 0.166687 9.0013 0.166687Z"
                          fill="#EEEEEE"
                        />
                      </svg>
                    </span>
                  </div>
                  {errors.newPassword && (
                    <FieldValidationText
                      errorMessage={errors.newPassword?.message}
                    />
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="confirmPassword">
                  <InputLabel text={t("Confirm New Password")} />
                  <div className="input-group position-relative mb-2">
                    <InputField
                      className=""
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder={t("Enter new password")}
                      {...register("confirmPassword", {
                        required: t("This field is required"),
                        validate: (value) => {
                          if (value != watch("newPassword")) {
                            return t("Passwords do not match");
                          }
                          return true;
                        },
                        pattern: {
                          value: PASSWORD_REGEX,
                          message: t("Please enter a valid password"),
                        },
                      })}
                      onChange={(e) =>
                        handleChange(e, PASSWORD_DISALLOWED_REGEX)
                      }
                    />
                    <span
                      className={`showPassword add position-absolute ${
                        showConfirmPassword ? "active" : ""
                      }`}
                      onClick={() => setShowConfirmPassword((prev) => !prev)}
                      style={{ cursor: "pointer" }}
                    >
                      <svg
                        width="18"
                        height="12"
                        viewBox="0 0 18 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9.0013 3.66669C7.7138 3.66669 6.7288 4.67752 6.7288 6.00002C6.7288 7.32252 7.7138 8.33335 9.0013 8.33335C10.2888 8.33335 11.2746 7.32252 11.2746 6.00002C11.2746 4.67752 10.2888 3.66669 9.0013 3.66669ZM9.0013 9.88919C6.88047 9.88919 5.2138 8.17835 5.2138 6.00002C5.2138 3.82252 6.88047 2.11085 9.0013 2.11085C11.1221 2.11085 12.7888 3.82252 12.7888 6.00002C12.7888 8.17835 11.1221 9.88919 9.0013 9.88919ZM9.0013 0.166687C5.2138 0.166687 1.95547 2.57752 0.667969 6.00002C1.95547 9.42252 5.2138 11.8334 9.0013 11.8334C12.7888 11.8334 16.0471 9.42252 17.3346 6.00002C16.0471 2.57752 12.7888 0.166687 9.0013 0.166687Z"
                          fill="#EEEEEE"
                        />
                      </svg>
                    </span>
                  </div>
                  {errors?.confirmPassword && (
                    <FieldValidationText
                      errorMessage={errors.confirmPassword?.message}
                    />
                  )}
                </Form.Group>
                <ButtonWithLoading
                  variant="primary"
                  type="submit"
                  className="fw-500 font-dubai d-block w-100 mb-3 mb-md-0"
                  text={!user?.password_required ? t("Update") : t("Submit")}
                  disabled={!isValid || !isDirty || isSubmitting}
                  isLoading={status == "pending"}
                />
              </Form>
            </Col>
            <Col md={6} className="colList">
              <span className="d-block fw-400 title mb-3">{t("Hints")}</span>
              <ListGroup as="ul" className="hintList">
                <ListGroup.Item
                  as="li"
                  className={`d-flex align-items-start ${
                    /^.{8,}$/.test(watch("newPassword")) && "focus"
                  }`}
                >
                  <span className="icon me-2">
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="6" cy="6" r="6" fill="#737373" />
                      <path
                        d="M9.72686 3.44035C9.57343 3.29853 9.32469 3.29853 9.17124 3.44035L4.46433 7.79076L2.65507 6.11855C2.50165 5.97673 2.25291 5.97675 2.09945 6.11855C1.94602 6.26035 1.94602 6.49024 2.09945 6.63206L4.18652 8.561C4.3399 8.7028 4.58882 8.70271 4.74214 8.561L9.72686 3.95387C9.88029 3.81207 9.88028 3.58216 9.72686 3.44035Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <span className="text">{t("mincharactersText")}</span>
                </ListGroup.Item>
                <ListGroup.Item
                  as="li"
                  className={`d-flex align-items-start ${
                    /[a-z]/g.test(watch("newPassword")) && "focus"
                  }`}
                >
                  <span className="icon me-2">
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="6" cy="6" r="6" fill="#737373" />
                      <path
                        d="M9.72686 3.44035C9.57343 3.29853 9.32469 3.29853 9.17124 3.44035L4.46433 7.79076L2.65507 6.11855C2.50165 5.97673 2.25291 5.97675 2.09945 6.11855C1.94602 6.26035 1.94602 6.49024 2.09945 6.63206L4.18652 8.561C4.3399 8.7028 4.58882 8.70271 4.74214 8.561L9.72686 3.95387C9.88029 3.81207 9.88028 3.58216 9.72686 3.44035Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <span className="text">{t("lowercaseLetterText")}</span>
                </ListGroup.Item>
                <ListGroup.Item
                  as="li"
                  className={`d-flex align-items-start ${
                    /[A-Z]/g.test(watch("newPassword")) && "focus"
                  }`}
                >
                  <span className="icon me-2">
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="6" cy="6" r="6" fill="#737373" />
                      <path
                        d="M9.72686 3.44035C9.57343 3.29853 9.32469 3.29853 9.17124 3.44035L4.46433 7.79076L2.65507 6.11855C2.50165 5.97673 2.25291 5.97675 2.09945 6.11855C1.94602 6.26035 1.94602 6.49024 2.09945 6.63206L4.18652 8.561C4.3399 8.7028 4.58882 8.70271 4.74214 8.561L9.72686 3.95387C9.88029 3.81207 9.88028 3.58216 9.72686 3.44035Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <span className="text">{t("uppercaseLetterText")}</span>
                </ListGroup.Item>
                <ListGroup.Item
                  as="li"
                  className={`d-flex align-items-start ${
                    /^.*\d+.*$/.test(watch("newPassword")) && "focus"
                  }`}
                >
                  <span className="icon me-2">
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="6" cy="6" r="6" fill="#737373" />
                      <path
                        d="M9.72686 3.44035C9.57343 3.29853 9.32469 3.29853 9.17124 3.44035L4.46433 7.79076L2.65507 6.11855C2.50165 5.97673 2.25291 5.97675 2.09945 6.11855C1.94602 6.26035 1.94602 6.49024 2.09945 6.63206L4.18652 8.561C4.3399 8.7028 4.58882 8.70271 4.74214 8.561L9.72686 3.95387C9.88029 3.81207 9.88028 3.58216 9.72686 3.44035Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <span className="text">{t("leastNumberText")}</span>
                </ListGroup.Item>
                <ListGroup.Item
                  as="li"
                  className={`d-flex align-items-start ${
                    /^.*[!@#$%^&*()\-_=+[{\]};:'",<.>/?].*$/.test(
                      watch("newPassword")
                    ) && "focus"
                  }`}
                >
                  <span className="icon me-2">
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="6" cy="6" r="6" fill="#737373" />
                      <path
                        d="M9.72686 3.44035C9.57343 3.29853 9.32469 3.29853 9.17124 3.44035L4.46433 7.79076L2.65507 6.11855C2.50165 5.97673 2.25291 5.97675 2.09945 6.11855C1.94602 6.26035 1.94602 6.49024 2.09945 6.63206L4.18652 8.561C4.3399 8.7028 4.58882 8.70271 4.74214 8.561L9.72686 3.95387C9.88029 3.81207 9.88028 3.58216 9.72686 3.44035Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <span className="text">
                    {t("Include at least one special character")}
                  </span>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
