import { useCallback, useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import InputLabel from "../../Components/Form/InputLabel";
import InputField from "../../Components/Form/InputField";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { handleLogin } from "../../redux/authSlice";
import { selectLangState } from "../../redux/configurationSlice";
import { AuthAPI } from "../../apis/authApi";
import { toast } from "react-toastify";
import ButtonWithLoading from "../../Components/buttons/ButtonWithLoading";
import FieldValidationText from "../../Components/Global/fieldValidationText";
import {
  PASSWORD_DISALLOWED_REGEX,
  PASSWORD_REGEX,
} from "../../utils/constants";
import { MoengageService } from "../../services/moengage";
import useForm from "../../hooks/useForm";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const SetupPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [token, setToken] = useState("");
  let [searchParams, setSearchParams] = useSearchParams();
  const { lang } = useSelector(selectLangState);
  const [isActive, setIsActive] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors, isDirty, isValid, isSubmitting },
    handleChange,
  } = useForm({
    mode: "all",
    defaultValues: {
      terms_and_conditions: true,
    },
  });
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
    setIsActive(!isActive);
  };

  const onSubmit = useCallback(
    async (values) => {
      try {
        if (!executeRecaptcha) {
          toast.error(t("Captcha missing"));
          return;
        }
        const data = {
          company: "ESD",
          language: lang?.startsWith("ar") ? "ar" : "en",
          platform: "website",
          password: values?.password,
          confirm_password: values?.confirmPassword,
          exclusive_updates: values?.exclusive_updates ?? false,
          terms_and_conditions: values?.terms_and_conditions ?? true,
        };
        const result = await executeRecaptcha("register");

        if (result) {
          data.captcha_token = result;
        }
        if (location?.state?.email) {
          data.email = location?.state?.email;
        }
        let res = null;
        if (searchParams.get("token")) {
          data.token = searchParams.get("token");
          res = await AuthAPI.updatePassword(data);
          toast.success(res?.message);
          navigate("/login");
        } else if (location?.state?.email) {
          res = await AuthAPI.registerUser(data);
          dispatch(
            handleLogin({
              token: res?.auth?.access_token,
              user: res?.user_profile,
            })
          );
          MoengageService.registrationSuccess({
            user_id: res?.user_profile?.user_id,
            "Account Status": res?.user_profile?.status,
            app_language: lang?.startsWith("ar") ? "ar" : "en",
            registration_method: res?.user_profile?.emirate_id,
          });
          MoengageService.userProfileDetails({
            "Account Status": res?.user_profile?.status,
            user_country_of_residence: res?.user_profile?.country_of_residence,
            user_preferred_location:
              res?.user_profile?.preferred_location ?? "",
            user_preferred_currency:
              res?.user_profile?.preferred_currency ?? "",
            user_app_language: lang?.startsWith("ar") ? "ar" : "en",
            is_email_verified: res?.user_profile?.is_email_verified,
          });
          toast.success(res?.message);
        } else {
          res = await AuthAPI.resetPassword(data);
          toast.success(res?.message);
          navigate("/login");
        }
      } catch (error) {
        toast.error(error?.message);
      }
    },
    [executeRecaptcha]
  );

  const captchaLoad = async () => {
    try {
      const token = await executeRecaptcha("register");
      setToken(token);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    executeRecaptcha && captchaLoad();
  }, [executeRecaptcha]);
  return (
    <>
      <div className="bg-auth">
        <Image
          src="/images/bg-auth.png"
          alt="icon-uae-pass"
          className="d-inline-block align-middle me-2"
        />
      </div>
      <div className="auth d-flex align-items-center justify-content-center">
        <div className="center-content w-100">
          <h1 className="fw-700 text-white">{t("Setup password")}</h1>
          <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Form.Group className="mb-3" controlId="passwordField">
              <InputLabel className="text-white" text={`${t("Password")}`} />
              <div className="input-group position-relative mb-2">
                <InputField
                  className="text-white"
                  type={showPassword ? "text" : "password"}
                  placeholder={t("Enter your password here")}
                  {...register("password", {
                    required: t("This field is required"),
                    pattern: {
                      value: PASSWORD_REGEX,
                      message: t("Please enter a valid password"),
                    },
                  })}
                  onChange={(e) => handleChange(e, PASSWORD_DISALLOWED_REGEX)}
                />
                <span
                  className={`showPassword position-absolute ${
                    isActive ? "active" : ""
                  }`}
                  onClick={togglePasswordVisibility}
                  style={{ cursor: "pointer" }}
                >
                  <svg
                    width="18"
                    height="12"
                    viewBox="0 0 18 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.0013 3.66669C7.7138 3.66669 6.7288 4.67752 6.7288 6.00002C6.7288 7.32252 7.7138 8.33335 9.0013 8.33335C10.2888 8.33335 11.2746 7.32252 11.2746 6.00002C11.2746 4.67752 10.2888 3.66669 9.0013 3.66669ZM9.0013 9.88919C6.88047 9.88919 5.2138 8.17835 5.2138 6.00002C5.2138 3.82252 6.88047 2.11085 9.0013 2.11085C11.1221 2.11085 12.7888 3.82252 12.7888 6.00002C12.7888 8.17835 11.1221 9.88919 9.0013 9.88919ZM9.0013 0.166687C5.2138 0.166687 1.95547 2.57752 0.667969 6.00002C1.95547 9.42252 5.2138 11.8334 9.0013 11.8334C12.7888 11.8334 16.0471 9.42252 17.3346 6.00002C16.0471 2.57752 12.7888 0.166687 9.0013 0.166687Z"
                      fill="#EEEEEE"
                    />
                  </svg>
                </span>
              </div>
              {errors?.password && (
                <FieldValidationText errorMessage={errors?.password?.message} />
              )}
            </Form.Group>
            <Form.Group className="mb-4" controlId="rePasswordField">
              <InputLabel
                className="text-white"
                text={`${t("Confirm your Password")}`}
              />
              <div className="input-group position-relative mb-2">
                <InputField
                  className="text-white"
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder={t("Re-enter your password here")}
                  {...register("confirmPassword", {
                    required: t("This field is required"),
                    validate: (value) => {
                      if (value !== watch("password")) {
                        return t("The passwords must match.");
                      }
                      return true;
                    },
                    pattern: {
                      value: PASSWORD_REGEX,
                      message: t("Please enter a valid password"),
                    },
                  })}
                  onChange={(e) => handleChange(e, PASSWORD_DISALLOWED_REGEX)}
                />
                <span
                  className={`showPassword position-absolute ${
                    showConfirmPassword ? "active" : ""
                  }`}
                  onClick={() => setShowConfirmPassword((prev) => !prev)}
                  style={{ cursor: "pointer" }}
                >
                  <svg
                    width="18"
                    height="12"
                    viewBox="0 0 18 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.0013 3.66669C7.7138 3.66669 6.7288 4.67752 6.7288 6.00002C6.7288 7.32252 7.7138 8.33335 9.0013 8.33335C10.2888 8.33335 11.2746 7.32252 11.2746 6.00002C11.2746 4.67752 10.2888 3.66669 9.0013 3.66669ZM9.0013 9.88919C6.88047 9.88919 5.2138 8.17835 5.2138 6.00002C5.2138 3.82252 6.88047 2.11085 9.0013 2.11085C11.1221 2.11085 12.7888 3.82252 12.7888 6.00002C12.7888 8.17835 11.1221 9.88919 9.0013 9.88919ZM9.0013 0.166687C5.2138 0.166687 1.95547 2.57752 0.667969 6.00002C1.95547 9.42252 5.2138 11.8334 9.0013 11.8334C12.7888 11.8334 16.0471 9.42252 17.3346 6.00002C16.0471 2.57752 12.7888 0.166687 9.0013 0.166687Z"
                      fill="#EEEEEE"
                    />
                  </svg>
                </span>
              </div>
              {errors?.confirmPassword && (
                <FieldValidationText
                  errorMessage={errors.confirmPassword?.message}
                />
              )}
            </Form.Group>
            <Form.Group className="mb-4" controlId="agreement">
              <Form.Check
                type="checkbox"
                {...register("terms_and_conditions", {
                  required: t("Please accept terms and conditions"),
                })}
                label={
                  <>
                    <span className="fw-400 text-white">
                      {t("I agree to the")}{" "}
                      <Link className="text-white" to="/terms-and-condition">
                        {t("Terms of Use")}
                      </Link>{" "}
                      {/* {t("and")}{" "} */}
                      {/* <span className="d-block">
                        <Link className="text-white" to="/privacy-policy">
                          {t("End User license Agreement")}
                        </Link>
                      </span> */}
                    </span>
                  </>
                }
                className={`custom-checkbox d-flex align-items-center ${
                  errors?.terms_and_conditions && "border-red"
                }`}
              />
              {errors?.terms_and_conditions && (
                <FieldValidationText
                  errorMessage={errors?.terms_and_conditions?.message}
                />
              )}
            </Form.Group>
            <Form.Group className="mb-4" controlId="exclusive-offers">
              <Form.Check
                type="checkbox"
                {...register("exclusive_updates")}
                label={
                  <>
                    <span className="fw-400 text-white">
                      {t("Keep me updated about Exclusive offers")}
                    </span>
                  </>
                }
                className="custom-checkbox d-flex align-items-center"
              />
            </Form.Group>
            <ButtonWithLoading
              variant="primary"
              type="submit"
              className="fw-500 font-dubai d-block w-100 mb-3 mb-md-0"
              text={t("Update and login")}
              disabled={!isValid || !isDirty || isSubmitting}
              isLoading={isSubmitting}
            />
          </Form>
        </div>
      </div>
    </>
  );
};

export default SetupPassword;
