import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Form, Image, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import InputField from "../../Form/InputField";
import OtpInput from "react-otp-input";
import { Controller } from "react-hook-form";
import { AuthAPI } from "../../../apis/authApi";
import {
  phoneNumberForDB,
  stringWithdotinMiddle,
} from "../../../utils/utilityFunctions";
import ButtonWithLoading from "../../buttons/ButtonWithLoading";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { selectLangState } from "../../../redux/configurationSlice";
import { handleUSer } from "../../../redux/authSlice";
import PhoneNumberField from "../../Form/phoneNumber";
import FieldValidationText from "../../Global/fieldValidationText";
import { PHONE_REGEX } from "../../../utils/constants";
import { MoengageService } from "../../../services/moengage";
import useForm from "../../../hooks/useForm";
import OtpResponseModal from "../OtpResponseModal";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const MobileEmailModal = ({
  show,
  close,
  type = "phone",
  refetch,
  ...rest
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [token, setToken] = useState("");
  const { t } = useTranslation();
  const [otpModalShow, setOtpModalShow] = useState(false);
  const [modalState, setModalState] = useState({
    status: "",
    title: "",
    description: "",
    btnText: "",
  });
  const dispatch = useDispatch();
  const { lang } = useSelector(selectLangState);
  const [timer, setTimer] = useState(40);
  const [isResending, setIsResending] = useState(false);
  const {
    handleSubmit,
    control,
    watch,
    register,
    setValue,
    getValues,
    trigger,
    formState: {
      errors,
      isSubmitting,
      isDirty,
      isValid,
      touchedFields,
      validatingFields,
      dirtyFields,
    },
    handleChange,
  } = useForm({
    mode: "all",
  });
  const [tab, setTab] = useState(1);
  const handleContinue = () => {
    setTab((prev) => prev + 1);
  };

  const handleResponse = (response) => {
    if (response?.status === "WRONG_ATTEMPT") {
      toast.error(response?.title);
    } else {
      setModalState({
        status: response?.status,
        title: response?.title,
        description: response?.desc,
        btnText: response?.cta_title ?? "",
      });
      setOtpModalShow(true);
    }
  };

  useEffect(() => {
    let intervalId;

    const startTimer = () => {
      intervalId = setInterval(() => {
        if (timer > 0) {
          setTimer((prevTimer) => prevTimer - 1);
        } else {
          clearInterval(intervalId);
        }
      }, 1000);
    };
    if (tab === 4) {
      startTimer();
    }

    return () => clearInterval(intervalId);
  }, [timer, tab]);

  const handleResendClick = useCallback(async () => {
    if (!isResending) {
      setIsResending(true);
      try {
        const payload = {
          language: lang?.startsWith("ar") ? "ar" : "en",
          ...(type === "phone"
            ? { mobile_no: phoneNumberForDB(watch("mobile")) }
            : { email: watch("email") }),
          otp_type: type === "phone" ? "UPDATE_PHONE" : "UPDATE_EMAIL",
        };
        if (!executeRecaptcha) {
          toast.error(t("Captcha missing"));
          return;
        }
        const result = await executeRecaptcha("otp");

        if (result) {
          payload.captcha_token = result;
        }
        const response = await handleOtp(payload);
        setOtpModalShow(false);
        setTimer(40);
        setValue("otp", "");
      } catch (error) {
        console.error("Error during OTP resend:", error);
      } finally {
        setIsResending(false);
      }
    }
  }, [executeRecaptcha]);

  const onSuccess = (response) => {
    setTab(4);
  };
  const onError = (error) => {
    toast.error(error?.message);
  };
  const {
    mutateAsync: handleOtp,
    status,
    error,
  } = useMutation({
    mutationFn: (data) => AuthAPI.sendOtp(data),
    onSuccess,
    onError,
  });

  const {
    mutateAsync: updateUser,
    status: userStatus,
    error: userError,
  } = useMutation({
    mutationFn: (data) => AuthAPI.updateUser(data),
    onSuccess: (response) => {
      dispatch(handleUSer({ user: response?.user_profile }));
      setTab(3);
      MoengageService.userProfileDetails({
        "Account Status": response?.user_profile?.status,
        user_country_of_residence: response?.user_profile?.country_of_residence,
        user_preferred_location:
          response?.user_profile?.preferred_location ?? "",
        user_preferred_currency:
          response?.user_profile?.preferred_currency ?? "",
        user_app_language: lang?.startsWith("ar") ? "ar" : "en",
        is_email_verified: response?.user_profile?.is_email_verified,
      });
    },
    onError: (error) => {
      toast.error(error?.message);
    },
  });

  const {
    mutateAsync: verify,
    status: verifyStatus,
    error: verifyError,
  } = useMutation({
    mutationFn: (data) => AuthAPI.verifyOtp(data),
    onSuccess: async (response) => {
      try {
        if (response?.otp_attempts_dialog?.status) {
          handleResponse(response?.otp_attempts_dialog);
        } else {
          const payload = {
            language: lang?.startsWith("ar") ? "ar" : "en",
            ...(type === "phone"
              ? { mobile_no: phoneNumberForDB(watch("mobile")) }
              : { email: watch("email") }),
          };
          refetch();
          dispatch(handleUSer({ user: response?.user_profile }));
          setTab(5);
        }
        // await updateUser(payload);
      } catch (error) {
        toast.error(error?.message);
      }
    },
    onError: (error) => {
      toast.error(error?.message);
    },
  });

  const {
    mutateAsync: verifyAccount,
    status: verifyAccountStatus,
    error: verifyAccountError,
  } = useMutation({
    mutationFn: (data) => AuthAPI.verifyAccount(data),
    onSuccess: (response) => {
      try {
        setTab(3);
      } catch (error) {
        toast.error(error?.message);
      }
    },
    onError: (error) => {
      toast.error(error?.message);
    },
  });

  const verifyPassword = async () => {
    try {
      const isValid = await trigger(["password"]);
      if (!isValid) {
        return;
      }

      const { password } = getValues();
      const payload = {
        language: lang?.startsWith("ar") ? "ar" : "en",
        password: password,
      };
      await verifyAccount(payload);
    } catch (error) {
      console.log(error);
    }
  };

  const sendOtp = useCallback(
    async (data) => {
      try {
        const payload = {
          language: lang?.startsWith("ar") ? "ar" : "en",
          ...(type === "phone"
            ? { mobile_no: phoneNumberForDB(data.mobile) }
            : { email: data.email }),
          otp_type: type === "phone" ? "UPDATE_PHONE" : "UPDATE_EMAIL",
        };
        if (!executeRecaptcha) {
          toast.error(t("Captcha missing"));
          return;
        }
        const result = await executeRecaptcha("otp");

        if (result) {
          payload.captcha_token = result;
        }
        await handleOtp(payload);
      } catch (error) {
        console.log(error);
      }
    },
    [executeRecaptcha]
  );

  const verfiyOtp = useCallback(
    async (data) => {
      try {
        const payload = {
          language: lang?.startsWith("ar") ? "ar" : "en",
          ...(type === "phone"
            ? { mobile_no: phoneNumberForDB(data.mobile) }
            : { email: data.email }),
          otp: data.otp,
          otp_type: type === "phone" ? "UPDATE_PHONE" : "UPDATE_EMAIL",
        };
        if (!executeRecaptcha) {
          toast.error(t("Captcha missing"));
          return;
        }
        const result = await executeRecaptcha("otp");

        if (result) {
          payload.captcha_token = result;
        }
        await verify(payload);
      } catch (error) {
        console.log(error);
      }
    },
    [executeRecaptcha]
  );

  const handleClose = () => {
    setTab(1);
    close();
  };

  const captchaLoad = async () => {
    try {
      const token = await executeRecaptcha("otp");
      setToken(token);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    executeRecaptcha && captchaLoad();
  }, [executeRecaptcha]);

  return (
    <>
      <Modal
        show={show}
        className={`accountModal ${otpModalShow && "opacity-0"}`}
        centered
        {...rest}
      >
        {(tab === 1 || tab === 2) && (
          <Modal.Header>
            <Modal.Title className="font-bukra fw-500">
              {t("Verify your identity")}
            </Modal.Title>
          </Modal.Header>
        )}
        {tab === 3 && (
          <Modal.Header>
            <Modal.Title className="font-bukra fw-500">
              {type === "phone" ? t("Edit Mobile Number") : t("Edit Email")}
            </Modal.Title>
          </Modal.Header>
        )}
        {tab === 4 && (
          <Modal.Header>
            <Modal.Title className="font-bukra fw-500 d-flex align-items-start gap-3">
              <span
                className="backStep cursor-pointer mt-1"
                onClick={() => setTab((prev) => prev - 1)}
              >
                <svg
                  width="20"
                  height="16"
                  viewBox="0 0 20 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.25213 0.292458C8.64289 0.682742 8.64328 1.31591 8.253 1.70667L3.41211 6.55352H19C19.5523 6.55352 20 7.00124 20 7.55352C20 8.1058 19.5523 8.55352 19 8.55352H3.41211L8.253 13.4004C8.64328 13.7912 8.64289 14.4243 8.25212 14.8146C7.86136 15.2049 7.22819 15.2045 6.83791 14.8137L0.292457 8.26019C-0.097486 7.86977 -0.0974857 7.23727 0.292458 6.84685L6.83791 0.293329C7.2282 -0.097436 7.86136 -0.0978258 8.25213 0.292458Z"
                    fill="#008755"
                  />
                </svg>
              </span>
              <span className="title-text">
                {type === "phone"
                  ? t("Verify your Mobile Number")
                  : t("Verify your Email")}
              </span>
            </Modal.Title>
          </Modal.Header>
        )}

        <Button
          className="closeBtn primary-color text-decoration-none fw-700"
          variant="link"
          onClick={handleClose}
        >
          {t("Close")}
        </Button>
        <Modal.Body className="">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
            }}
            autoComplete="off"
          >
            {tab === 1 && (
              <>
                <p className="mb-4">
                  {type === "phone"
                    ? t(
                        "Please verify your identity before proceeding to change your mobile number."
                      )
                    : t(
                        "Please verify your identity before proceeding to change your email."
                      )}
                </p>
                <Button
                  variant="primary"
                  type="submit"
                  className="fw-500 font-dubai d-block w-100 mb-3 mb-md-0"
                  onClick={handleContinue}
                >
                  {t("Proceed with Password")}
                </Button>
              </>
            )}
            {tab === 2 && (
              <>
                <p className="mb-4">
                  {type === "phone"
                    ? t(
                        "Please verify your identity before proceeding to change your mobile number."
                      )
                    : t(
                        "Please verify your identity before proceeding to change your email."
                      )}
                </p>
                <Form.Group className="mb-3 ">
                  <p className="fw-500 text-black">{t("Password")}</p>
                  <InputField
                    className="text-black"
                    type="password"
                    placeholder="********"
                    {...register("password", {
                      required: t("Password is required"),
                    })}
                  />
                  {errors.password && (
                    <div className="mt-1">
                      <FieldValidationText
                        errorMessage={errors.password?.message}
                      />
                    </div>
                  )}
                </Form.Group>
                <ButtonWithLoading
                  variant="primary"
                  type="submit"
                  className="fw-500 font-dubai d-block w-100 mb-3 mb-md-0"
                  text={t("Continue")}
                  isLoading={verifyAccountStatus == "pending"}
                  disabled={
                    verifyAccountStatus == "pending" || !dirtyFields["password"]
                  }
                  onClick={verifyPassword}
                />
              </>
            )}
            {tab === 3 && (
              <>
                <p className="mb-4">
                  {type === "phone"
                    ? t(
                        "Please enter your new mobile number that you want to update."
                      )
                    : t(
                        "Please enter your new email address that you want to update."
                      )}
                </p>
                <Form.Group className="mb-3 ">
                  <p className="fw-500 text-black">
                    {type === "phone" ? t("Mobile Number") : t("Email")}
                  </p>

                  <Controller
                    name={type === "phone" ? "mobile" : "email"}
                    control={control}
                    rules={{
                      required:
                        type === "phone"
                          ? t("Mobile number is required")
                          : t("Email is required"),
                      ...(type === "phone"
                        ? {
                            validate: (value) => {
                              if (value && !value.match(PHONE_REGEX))
                                return t("Please enter a valid phone number");
                              return null;
                            },
                          }
                        : {}),
                    }}
                    render={({ field }) => (
                      <>
                        {type === "phone" ? (
                          <PhoneNumberField field={field} trigger={trigger} />
                        ) : (
                          <InputField
                            className="text-black"
                            placeholder="email@gmail.com"
                            {...field}
                            onChange={handleChange}
                          />
                        )}
                      </>
                    )}
                  />
                  {errors.mobile && (
                    <div className="mt-1">
                      <FieldValidationText
                        errorMessage={errors.mobile?.message}
                      />
                    </div>
                  )}
                  {errors.email && (
                    <div className="mt-1">
                      <FieldValidationText
                        errorMessage={errors.email?.message}
                      />
                    </div>
                  )}
                </Form.Group>
                <ButtonWithLoading
                  variant="primary"
                  type="submit"
                  className="fw-500 font-dubai d-block w-100 mb-3 mb-md-0"
                  text={t("Update")}
                  isLoading={status == "pending"}
                  disabled={!isDirty || !isValid}
                  onClick={handleSubmit(sendOtp)}
                />
              </>
            )}
            {tab === 4 && (
              <>
                <p className="mb-4">
                  {type === "phone"
                    ? t(
                        `We’ve sent you a 6 digit verification code to your mobile number`
                      )
                    : t(`We’ve sent an OTP code to your email`)}{" "}
                  <strong>
                    {stringWithdotinMiddle(
                      watch(type == "phone" ? "mobile" : "email")
                    )}
                  </strong>
                </p>
                <Form.Group className="mb-3">
                  <p className="fw-500 text-black">
                    {t("Please enter the 6 digit code")}
                  </p>
                  <Controller
                    name="otp"
                    control={control}
                    rules={{
                      required: t("OTP is required"),
                      minLength: {
                        value: 6,
                        message: t("Please enter the 6 digit code"),
                      },
                    }}
                    render={({ field }) => (
                      <OtpInput
                        {...field}
                        value={field.value}
                        onChange={(value) => {
                          field.onChange(value);
                        }}
                        numInputs={6}
                        containerStyle={"otpWrap"}
                        renderSeparator={<span></span>}
                        renderInput={(props) => <input {...props} />}
                        inputStyle="form-control"
                        inputType="number"
                        isInputNum
                      />
                    )}
                  />
                  {errors?.otp && (
                    <div className="mt-1">
                      <FieldValidationText errorMessage={errors.otp?.message} />
                    </div>
                  )}
                </Form.Group>
                <ButtonWithLoading
                  variant="primary"
                  type="submit"
                  className="fw-500 font-dubai d-block w-100 mb-3 mb-md-0"
                  text={t("Verify")}
                  disabled={!isDirty || !isValid}
                  isLoading={
                    verifyStatus == "pending" || userStatus == "pending"
                  }
                  onClick={handleSubmit(verfiyOtp)}
                />
                <div className="d-flex gap-2 pt-4 fw-400 fs-16">
                  <span className="text-black">
                    {t("Didn’t received the code yet?")}
                  </span>
                  {timer > 0 ? (
                    <>
                      <span className="primary-color numberDirection ms-auto">
                        {t("{{second}} sec", { second: timer })}
                      </span>
                    </>
                  ) : (
                    <span className="primary-color">
                      <span
                        className="primary-color text-decoration-none cursor-pointer"
                        onClick={handleResendClick}
                        aria-disabled={isResending}
                      >
                        {t("Send again")}
                      </span>
                    </span>
                  )}
                </div>
              </>
            )}

            {tab === 5 && (
              <>
                <div className="text-center">
                  <div className="circle-img d-inline-block">
                    <Image
                      src="/images/tick-img.png"
                      alt="Trash image"
                      fluid
                      className="d-block w-100"
                    />
                  </div>
                  <h4 className="font-bukra fw-500 mb-5">
                    {t(
                      `${
                        type === "phone" ? "Mobile number" : "Email"
                      } has been Updated`
                    )}
                  </h4>
                </div>

                <Row>
                  <Col md={12} xs={12}>
                    <Button
                      variant="primary"
                      className="fw-500 font-dubai d-block w-100 mb-3 mb-md-0"
                      onClick={handleClose}
                    >
                      {t("Done")}
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Form>
        </Modal.Body>
      </Modal>
      {otpModalShow && (
        <OtpResponseModal
          show={otpModalShow}
          close={() => setOtpModalShow(false)}
          title={modalState?.title}
          description={modalState?.description}
          btnText={modalState?.btnText}
          sendOtp={handleResendClick}
          timer={timer}
          status={modalState?.status}
          isLoading={isResending}
        />
      )}
    </>
  );
};

export default MobileEmailModal;
